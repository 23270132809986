/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~animate.css/animate.min.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'Comfortaa';
  src: url('./assets/fonts/Comfortaa-Bold.ttf');
}

.tagField .searchbar-input.sc-ion-searchbar-md {
  border-radius: 30px;
}

.tagFieldWithTagChoicesOpened .searchbar-input.sc-ion-searchbar-md {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.small-modal {
  --height: 50%;
  --border-radius: 25px;
}

.create-catchphrase-modal {
  --height: 85%;
  --max-height: 750px;
  --border-radius: 55pt 55pt 0px 0px;
}

.create-catchphrase-modal::part(content) {
  position: absolute;
  bottom: 0;
}

.searching-component-modal {
}

.copied-alert .alert-wrapper {
  min-width: 100px !important;
  align-self: end;
  margin-bottom: 75px;
}


.ion-flex-container {
  display: flex;
}

.ion-flex-column {
  flex-direction: column;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

ion-button {
  text-transform: none;
}

ion-button[shape=circle] {
  --border-radius: 50%;
  width: 35px;
  height: 35px;
}

.infinite-scroll-color {
  margin-bottom: 25px;

  .infinite-loading-text {
    color: var(--ion-color-light) !important;
  }
}

app-home {
  background: var(--ion-color-primary);
}

.refresher-ios .refresher-pulling-icon, .refresher-ios .refresher-pulling-text,
.refresher-ios .refresher-refreshing-icon, .refresher-ios .refresher-refreshing-text {
  ion-spinner {
    color: var(--ion-color-light);
  }

  color: var(--ion-color-light);
}

.createTchatch {
  width: 61pt !important;
  height: 61pt !important;
  bottom: 50px;
  right: 4%;
  z-index: 999;
  white-space: initial;
  font-weight: bold;
  filter: drop-shadow(0pt 3pt 20pt #00000029);
}

.createTchatch ion-icon[slot='icon-only'] {
  font-size: 50px;
  color: var(--ion-color-light)
}

ion-textarea label {
  overflow: hidden;
}

.catchphraseTextArea label {
  box-shadow: 0 17px 27px #00000029;
}

.footer-segment-ios-app-webapp {
  padding-bottom: 30px;
}
